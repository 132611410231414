import React from 'react'
import classNames from 'classnames'
import styles from './Alert.module.scss'

const Alert = ({ message, success, error }) => (
  <span
    className={
      classNames(styles.alert, {
        [`${styles.alertSuccess}`]: success
      }, {
        [`${styles.alertError}`]: error
      })
    }>{message}</span>
)

export default Alert
