import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import classNames from 'classnames'
import fetch from 'isomorphic-fetch'
import styles from './contact.module.scss'
import { encode, validateEmail } from '../lib/utils'

// Components
import Layout from '../components/Layout'
import Section from '../components/Layout/Section'
import PageTitle from '../components/Elements/PageTitle'
import FieldInput from '../components/Form/FieldInput'
import FieldTextArea from '../components/Form/FieldTextArea'
import FieldSelect from '../components/Form/FieldSelect'
import Alert from '../components/Form/Alert'
import Button from '../components/Elements/Button'

const validate = (values) => {
  const errors = {}

  if (!values.subject) {
    errors.subject = "Please choose a subject"
  }

  if (!values.email) {
    errors.email = "Email is required"
  } else if (!validateEmail(values.email)) {
    errors.email = "Please enter a valid email address"
  }

  if (!values.message) {
    errors.message = "Message is required"
  }

  return errors
}

class Contact extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      success: null
    }
  }

  onSubmit(values, form) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values })
    })
      .then(() => {
        this.setState({ success: "Your message has been sent successfully!" })
        form.reset()
      })
      .catch(() => {
        return { [FORM_ERROR]: "Sorry, your message did not send. Please try again later." }
      })
  }

  render() {
    const { success } = this.state

    return (
      <Layout className={styles.contactPage}>
        <PageTitle title="Contact">
          Get in touch with our team
        </PageTitle>
        <Section>
          <p>
            Have questions or feedback? Use the form below and we'll get back
  to you as soon as possible.
          </p>
          <Form
            onSubmit={this.onSubmit}
            validate={validate}
            render={({ handleSubmit, submitError, reset, submitting, pristine, values }) => (
              <form
                name="contact"
                className={styles.form}
                onSubmit={handleSubmit}
                netlify="true"
                netlify-honeypot="bot-field"
              >
                <Field id="subject" name="subject" label="Subject" component={FieldSelect}>
                  <option value="">Choose a subject...</option>
                  <option value="General Inquiry">General Inquiry</option>
                  <option value="Request Install">Request Install</option>
                  <option value="Product Question">Product Question</option>
                  <option value="Feedback">Feedback</option>
                </Field>
                <Field id="email" type="text" name="email" label="Email" component={FieldInput} />
                <Field id="message" name="message" label="Message" component={FieldTextArea} />

                <input type="text" className={classNames("bot-field", styles.hide)} name="bot-field" />

                {success && <Alert message={success} success />}
                {submitError && <Alert message={submitError} error />}

                <div className="actions">
                  <Button disabled={submitting} primary>
                    {submitting ? "Sending..." : "Submit"}
                  </Button>
                </div>
              </form>
          )} />
        </Section>
      </Layout>
    )
  }
}

export default Contact
