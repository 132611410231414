import React from 'react'
import { Field } from 'react-final-form'
import styles from './FieldSelect.module.scss'

// Components
import FormGroup from './FormGroup'
import Label from './Label'

const FieldSelect =({ children, label, input, meta }) => (
  <FormGroup>
    <Label htmlFor={input.id}>{label}</Label>
    <Field className={styles.input} {...input} component="select">
      {children}
    </Field>
    {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
  </FormGroup>
)

export default FieldSelect
