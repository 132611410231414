import React from 'react'
import styles from './FieldTextArea.module.scss'

// Components
import FormGroup from './FormGroup'
import Label from './Label'

const FieldInput =({ label, input, meta }) => (
  <FormGroup>
    <Label htmlFor={input.id}>{label}</Label>
    <textarea className={styles.input} {...input} />
    {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
  </FormGroup>
)

export default FieldInput
