import React from 'react'
import styles from './FormGroup.module.scss'

const FormGroup = ({ children }) => (
  <div className={styles.formGroup}>
    {children}
  </div>
)

export default FormGroup
