import React from 'react'
import styles from './Label.module.scss'

const Label = ({ htmlFor, children }) => (
  <label className={styles.label} htmlFor={htmlFor}>
    {children}
  </label>
)

export default Label
